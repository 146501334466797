import Blamable               from '@/models/traits/Blamable';
import MapServiceModel        from '@/models/MapServiceModel';
import ProjectModel           from '@models/map/ProjectModel';
import StonecodeFileFileModel from '@models/file/StonecodeFileFileModel';
import TimestampAble          from '@widesk-core/models/traits/TimestampAble';
import WithProjectPagePath    from '@/models/traits/WithProjectPagePath';
import doc                    from '@widesk-core/decorators/doc';
import model                  from '@widesk-core/decorators/model';
import reverseResolvable      from '@widesk-core/decorators/reverseResolvable';
import { computed }           from 'mobx';
import { toJS }               from 'mobx';
import { getIdFromUrn }       from '@widesk-core/tools/identifier';
import getDefaultJsonData     from '@/ol/tools/getDefaultJsonData';

@model.urnResource('map')
@doc.path('/maps/{?id}')
export default class MapModel extends Blamable(TimestampAble(WithProjectPagePath(MapServiceModel))) {
	private declare _tsFilters: {
		'createdAt': string;
		'mapPlaces': id;
		'mapVersions': id;
		'project': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};

	@doc.string declare label: string;
	@doc.model(ProjectModel) declare project: ProjectModel;

	@computed
	public get geoDataJsonFile() {
		return reverseResolvable(this, StonecodeFileFileModel, {
			propertyName: 'geoDataJsonFile',
			modelIdProperty: 'urn',
			getModelIdFromResolvable: async file => {
				await file.typeSource.source.whenIsLoaded();
				return file.typeSource.source.entityUrn;
			},
			filters: ({ urns }) => ({
				'typeSource.type.reference': 'geodata_json' as const,
				'typeSource.source.entityUrn': urns,
				// TODO Filtrer sur le statut
			}),
			onFetchSuccess: m => m.fetchGeoDataJson(),
		});
	}

	public setGeoDataJson(geo: OL.jsonData) {
		this.geoDataJsonFile.set('data', geo);
	}

	public async fetchGeoDataJson() {
		const blob = await this.geoDataJsonFile.fetchBlob();
		const text = await blob?.text();

		const geo = JSON.parse(text || 'false') as OL.jsonData | false;

		if (geo) {
			this.setGeoDataJson(geo);
		}
	}

	@computed
	public get placeIds() {
		return this.geoDataJson?.zones.features
			.filter(zone => (zone.properties as any).entityUrn)
			.map(zone => getIdFromUrn((zone.properties as any).entityUrn!)) || [];
	}

	@computed get geoDataJson(): OL.jsonData | undefined {
		const json = toJS(this.geoDataJsonFile.get('data'));
		return this._migrateOldGeoJsonToNewVersion(json);
	}

	_migrateOldGeoJsonToNewVersion(json: any) {
		if (!json) {
			return getDefaultJsonData();
		}

		json.configurations = {
			...getDefaultJsonData().configurations,
			...json.configurations,
		};

		console.log(json);

		return json;
	}
}
