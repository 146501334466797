import ModalStore              from "@widesk-ui/stores/modalStore";
import Button                  from "../Button";
import { PropsWithChildren }   from "react";
import { Modal }               from "./Modal";
import globalModalStore        from "@widesk-ui/stores/globalModalStore";
import React                   from "react";
import ServiceModel            from '@models/ServiceModel';

export type AddMoreButtonProps = {
	modalStore: ModalStore;
	afterAllSuccess?: (m: ServiceModel) => void,
	cancelButtonText?: string,
	saveButtonText?: string,
	moreButtonText?: string,
} & PropsWithChildren;

export default function AddMoreButton(props: AddMoreButtonProps) {
	const { modalStore, afterAllSuccess } = props;

	return (
		<>
			<Button onClick={() => {
				modalStore.setIsOpen(false);
			}}>{props.cancelButtonText || 'Annuler'}</Button>
			<Button onClick={async () => {
				const props = { ...globalModalStore.modalsProps.at(-1) };
				const { children } = props;
				const { model } = React.isValidElement(children) ? children.props : { model: null };
				modalStore.setAdditionalProps({
					...modalStore.additionalProps,
					afterOpenChange: () => {
						props.afterOpenChange?.();
						afterAllSuccess?.(model);
					},
				});
				await modalStore.submit();
			}} type="primary">{props.saveButtonText || 'Enregistrer'}</Button>
			<Button onClick={async () => {
				const props = { ...globalModalStore.modalsProps.at(-1) };
				const { children } = props;
				const { model } = React.isValidElement(children) ? children.props : { model: null };
				modalStore.setAdditionalProps({
					...modalStore.additionalProps,
					afterOpenChange: () => {
						props.afterOpenChange?.();
						Modal.open({
							...props,
							children: React.isValidElement(children) && React.cloneElement<any>(children, {
								...(typeof children.props === 'object' ? children.props : {}),
								model: new model.modelClass(),
							}),
						});
					},
				});
				await modalStore.submit();
			}} type="primary">{props.moreButtonText || 'Enregistrer et ajouter à nouveau'}</Button>
		</>
	);
}