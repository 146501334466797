
import ProjectModel   from '@/models/services/map/ProjectModel';
import appStore       from '@/stores/appStore';
import { reaction }   from 'mobx';
import { useEffect }  from 'react';
import { useState }   from 'react';

export const useSetActiveProject = (id?: id) => {
	const [project, setProject] = useState<ProjectModel | null>(null);

	useEffect(() => reaction(() => appStore.activeProject, v => setProject(v)), []);
	useEffect(() => {
		(async () => {
			if (id) {
				await appStore.fetchProject(id);
			} else {
				appStore.resetActiveProject();
			}
		})();
	}, [id]);

	return project;
};

const useActiveProject = () => {	
	const [project, setProject] = useState<ProjectModel | null>(appStore.activeProject);
	useEffect(() => reaction(() => appStore.activeProject, v => setProject(v)), []);
	return project;
};

export default useActiveProject;